<template>
  <div class="about">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh" :backgroundPic="BannerKvData.backgroundPic"
      :backgroundPicRwd="BannerKvData.backgroundPicRwd" :bgcolor="'#fff'" />

    <section id="top_point">
      <div class="container w1000">
        <div class="inner">
          <div class="item">
            <div>
              <p class="small txt-white">Humanity</p>
              <h6 class="txt-white">{{ $t("humanity") }}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">Technology</p>
              <h6 class="txt-white">{{ $t("technology") }}</h6>
            </div>
          </div>
          <div class="item">
            <div>
              <p class="small txt-white">Care</p>
              <h6 class="txt-white">{{ $t("care") }}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="docter_total">
      <div class="w800 txt-center">
        <!-- <h3 class="eng">NUWA DOCTOR</h3> -->

        <h5 class="txt-bold">
          {{ $t("nuwaTeams") }}
          <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower" />
        </h5>

        <br />
        <p class="txt-dark_gray small">
          {{ $t("nuwaTeamsContent") }}
        </p>
      </div>
      <div class="container w1400">
        <div class="item" @click="
          toPage({
            name: 'AboutType',
            params: {
              classification: 'ivf'
            }
          })
        ">
          <div class="pic">
            <figure :style="{
              backgroundImage: `url(${require('@/assets/img/index/about_IVF_Center_bg_new.jpg')})`
            }"></figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA FERTILITY
              </h5>
              <p v-if="lang !== 'en'">
                {{ $t("introIvf") }}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{ $t("ivfContent") }}
              </p>
            </div>

            <!-- <div class="btn btn-link">
              <router-link :to="{
                name: 'Staff',
                params: {
                  classification: 'procreations',
                  typeId: 'p1',
                }

              }">{{$t('introTeams')}}</router-link>

            </div> -->
          </div>
        </div>
        <div class="item" @click="
          toPage({
            name: 'AboutType',
            params: {
              classification: 'tcm'
            }
          })
        ">
          <div class="pic">
            <figure :style="{
              backgroundImage: `url(${require('@/assets/img/About_NUWA/service_2_new.jpg')})`
            }"></figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA TCM CENTER
              </h5>
              <p v-if="lang !== 'en'">
                {{ $t("introTcm") }}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{ $t("tcmContent") }}
              </p>
            </div>

            <!-- <div class="btn btn-link">
              <router-link :to="{
                name: 'Staff',
                params: {
                  classification: 'traditionals',
                  typeId: 't1',
                }
              }">{{$t('introTeams')}}</router-link>

            </div> -->
          </div>
        </div>
        <div class="item" @click="
          toPage({
            name: 'AboutType',
            params: {
              classification: 'lab'
            }
          })
        ">
          <div class="pic">
            <figure :style="{
              backgroundImage: `url(${require('@/assets/img/About_NUWA/service_3_new.jpg')})`
            }"></figure>
          </div>
          <div class="text txt-center">
            <div>
              <h5 class="txt-bold">
                NUWA Lab
              </h5>
              <p v-if="lang !== 'en'">
                {{ $t("introlabs") }}
              </p>

              <p v-else v-html="npsp"></p>

              <p class="info small">
                {{ $t("aboutLabs") }}
              </p>
            </div>

            <!-- <div class="btn btn-link">
              <router-link :to="{
                name: 'Staff',
                params: {
                  classification: 'labs',
                  typeId: 'l1',
                }

              }">{{$t('introTeams')}}</router-link>

            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section id="advantage">
      <div class="w800 txt-center">
        <!-- <h3 class="eng">OUR ADVANTAGE</h3> -->
        <h5 class="txt-bold">
          {{ $t("advantage") }}
          <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower" />
        </h5>

        <br />
        <p class="txt-dark_gray small">
          {{ $t("advantageContent") }}
        </p>
      </div>
      <div class="container w1200">
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon4.svg" alt="" />
          <p>{{ $t("womenCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon5.svg" alt="" />
          <p>{{ $t("tcmCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon2.svg" alt="" />
          <p>{{ $t("customizeCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon3.svg" alt="" />
          <p>{{ $t("psyCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon7.svg" alt="" />
          <p>{{ $t("technologyCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon1.svg" alt="" />
          <p>{{ $t("vipCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon6.svg" alt="" />
          <p>{{ $t("labsCare") }}</p>
        </div>
        <div class="item">
          <img src="@/assets/img/About_NUWA/icon8.svg" alt="" />
          <p>{{ $t("femTech") }}</p>
        </div>
      </div>
    </section>

    <section id="location">
      <div class="container w1400">
        <div class="txt-center">
          <!-- <h3 class="eng">NUWA LOCATION</h3> -->
          <h5 class="txt-bold">
            {{ $t("location") }}
            <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower" />
          </h5>
        </div>

        <AboutTabs :articleData="articleData" @activedTab="clinicContent" />
        <div class="location_box">
          <div class="pic" ref="locationPicH">
            <swiper :options="swiperOption" v-if="clinicActivedContent.pic_array">
              <swiper-slide>
                <figure :style="{ backgroundImage: `url(${clinicActivedContent.pic_single})` }"></figure>
              </swiper-slide>
              <swiper-slide v-for="(item, key) in clinicActivedContent.pic_array" :key="key">
                <figure :style="{ backgroundImage: `url(${API_URL}${item})` }"></figure>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <figure v-if="!clinicActivedContent.pic_array"
              :style="{ backgroundImage: `url(${clinicActivedContent.pic_single})` }"></figure>
          </div>

          <div class="text" :style="{ height: `${txtHight}px` }">
            <h4 class="mb20">{{ clinicActivedContent.description }}</h4>
            <div v-html="clinicActivedContent.content"></div>
          </div>
        </div>
      </div>
    </section>

    <section id="ready" :style="{ backgroundImage: `url(${require('@/assets/img/About_NUWA/bg.jpg')})` }">
      <div class="text txt-white txt-center">
        <h3 class="eng">{{ $t("endText") }}</h3>
        <h5>
          WE ARE READY<img src="@/assets/img/deco_flower_white.svg" alt="" class="deco_flower" />
        </h5>

        <div class="btn btn-white" :class="[lang === 'en' ? 'btnEn' : '']" @click="clickHandler()">
          {{ $t("bookingNow") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import { getLocationPic } from '@/lib/public';
import {
  // mapMutations, mapGetters,
  mapState
} from 'vuex';

import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import AboutTabs from '@/components/about/AboutTabs.vue';
import pic01 from '@/assets/img/about_nuwa_bn_web.jpg';
import pic02 from '@/assets/img/about_nuwa_bn_mob.jpg';
// import pic02 from '@/assets/img/index/about_kv.jpg';

export default {
  name: 'aboutNuwa',
  components: {
    BannerKv,
    AboutTabs
  },
  data() {
    return {
      activedTab: '',
      clinicActivedContent: {
        content: '',
        description: '',
        pic_single: '',
        pic_array: []
      },
      npsp: '&nbsp',
      BannerKvData: {
        titleZh: this.$t('aboutNuwa'),
        titleEn: 'ABOUT NUWA',
        backgroundPic: pic01,
        backgroundPicRwd: pic02
      },
      swiperOption: {
        autoplay: true,
        speed: 1000,
        delay: 8000,
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#location .swiper-pagination',
          clickable: true
        }
      },
      API_URL: process.env.VUE_APP_API_NEW,
      picSet: [],
      txtHight: 0
    };
  },
  computed: {
    ...mapState(['lang', 'articles']),
    articleData() {
      return this.articles.filter(({ type }) => type === 'locations');
      // .map(({ cover_path, title, content }) => ({
      //   image: cover_path,
      //   title,
      //   content
      // }));
    }
  },
  methods: {
    clinicContent(activedData) {
      this.clinicActivedContent.content = activedData.content;
      this.clinicActivedContent.description = activedData.description;
      this.clinicActivedContent.pic_single = activedData.cover_path;
      this.clinicActivedContent.pic_array = activedData.data;
    },
    clickHandler() {
      this.$router.push({ name: 'Registration' });
    },
    clickLocationTab(item) {
      this.activedTab = item.id;
    },
    checkHeight() {
      this.txtHight = this.$refs.locationPicH.clientHeight;
    },
    toPage(routeInfo) {
      this.$router.push(routeInfo);
    }
  },
  watch: {
    articleData: {
      handler() {
        this.activedTab = this.articleData?.[0]?.id || '';
      },
      deep: true,
      immediate: true
    },
    clinicActivedContent: {
      handler() {
        setTimeout(() => {
          this.checkHeight();
        });
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.checkHeight();
  }
};
</script>
