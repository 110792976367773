<template>
  <ul class="location_tab w1200">
    <li ref="locationTab" v-for="(item, key) in articleData" :key="key">
      <span href="javascript:void(0)" :class="{
        'active': activedTab === item.id
      }" @click="clickLocationTab(item)">{{item.title}}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AboutTabs',
  props: ['articleData'],
  data() {
    return {
      activedTab: '',
    };
  },
  methods: {
     clickLocationTab(item) {
      this.$emit('activedTab', item);
      this.activedTab = item.id;
    //   this.$refs.locationTab.forEach((i) => {
    //       i.children[0].classList.remove('active');
    //   });
    //  setTimeout(() => { e.target.classList.add('active'); }, 100);
    },
  },
   watch: {
    articleData: {
      handler() {
        this.activedTab = this.articleData[0].id;
        this.$emit('activedTab', this.articleData[0]);
      },
      deep: true,
      immediate: true,
    },
  },

};
</script>
